import en from '~/locales/en.json'
import es from '~/locales/es-ES.json'
import id from '~/locales/id-ID.json'
import ja from '~/locales/ja-JP.json'
import pt from '~/locales/pt-PT.json'
import th from '~/locales/th-TH.json'
import vi from '~/locales/vi-VN.json'
import kr from '~/locales/ko-KR.json'
import zh_cn from '~/locales/zh-cn.json'
import zh_tw from '~/locales/zh-TW.json'
import hi from '~/locales/hi-IN.json'
import {LANGUAGE_COOKIE} from '~/constants/i18n'

export default defineI18nConfig(() => {
  let defaultLocale = 'en'

  const languageCookie = useCookie(LANGUAGE_COOKIE)

  // Init language if you have a cookie
  if (languageCookie.value) {
    defaultLocale = languageCookie.value
  }

  return {
    legacy: false,
    globalInjection: true,
    locale: defaultLocale,
    fallbackLocale: 'en',
    messages: {
      en,
      es,
      id,
      ja,
      pt,
      th,
      vi,
      kr,
      'zh-cn': zh_cn,
      'zh-tw': zh_tw,
      hi
    }
  }
})
